import {
  Component,
  ViewChildren,
  QueryList,
  ElementRef,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
import { Inject } from '@angular/core';

export interface PhoneNumberDialogData {
  title: string;
  object: User;
}

@Component({
  selector: 'app-phone-number-dialog',
  templateUrl: './phone-number-dialog.component.html',
  styleUrls: ['./phone-number-dialog.component.scss', '../dialogs.scss'],
  standalone: true,
  imports: [...ALL_IMPORTS],
})
export class PhoneNumberDialogComponent implements OnInit {
  @ViewChildren(
    'input1, input2, input3, input4, input5, input6, input7, input8',
  )
  inputs!: QueryList<ElementRef>;

  phoneNumber: string[] = new Array(8).fill('');

  phoneForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PhoneNumberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhoneNumberDialogData,
    private userService: UserService,
    private toastr: ToastrService,
  ) {
    this.phoneForm = new FormGroup({
      phone: new FormControl(this.data.object.phone || '', [
        Validators.required,
        Validators.pattern('^[+]?[0-9]{8,15}$'),
      ]),
    });
  }

  ngOnInit() {
    // Focus the first input on dialog open
    setTimeout(() => {
      this.inputs.first.nativeElement.focus();
    });
  }

  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Only allow numbers
    if (!/^\d*$/.test(value)) {
      input.value = '';
      return;
    }

    // Find the first empty position
    const firstEmptyIndex = this.phoneNumber.findIndex((digit) => !digit);

    // If trying to input at a position other than the first empty one, clear the input
    if (index !== firstEmptyIndex) {
      input.value = '';
      this.focusCorrectInput();
      return;
    }

    // Take only the last character if multiple characters were pasted
    value = value.slice(-1);
    input.value = value;

    if (value) {
      this.phoneNumber[index] = value;
      // Move to next input if available
      if (index < 7) {
        setTimeout(() => {
          const inputsArray = this.inputs.toArray();
          inputsArray[index + 1].nativeElement.focus();
        }, 0);
      }
    }
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    // Find the first empty position
    const firstEmptyIndex = this.phoneNumber.findIndex((digit) => !digit);

    // Allow only numbers, navigation keys, and deletion keys
    const allowedKeys = [
      'Backspace',
      'Delete',
      'Tab',
      'ArrowLeft',
      'ArrowRight',
    ];
    if (!allowedKeys.includes(event.key) && !/^\d$/.test(event.key)) {
      event.preventDefault();
      return;
    }

    // If trying to input at a position other than the first empty one, prevent input
    if (index !== firstEmptyIndex && !allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    if (event.key === 'Backspace') {
      if (this.phoneNumber[index]) {
        // If current position has a value, clear it
        this.phoneNumber[index] = '';
        const input = event.target as HTMLInputElement;
        input.value = '';
      } else {
        // Move to previous input if current is empty
        if (index > 0) {
          event.preventDefault();
          this.phoneNumber[index - 1] = '';
          setTimeout(() => {
            const inputsArray = this.inputs.toArray();
            const previousInput = inputsArray[index - 1].nativeElement;
            previousInput.value = '';
            previousInput.focus();
          }, 0);
        }
      }
    }
  }

  onFocus(index: number): void {
    this.focusCorrectInput();
  }

  onClick(index: number): void {
    this.focusCorrectInput();
  }

  private focusCorrectInput(): void {
    setTimeout(() => {
      const firstEmptyIndex = this.phoneNumber.findIndex((digit) => !digit);
      if (firstEmptyIndex !== -1) {
        const inputsArray = this.inputs.toArray();
        inputsArray[firstEmptyIndex].nativeElement.focus();
      }
    }, 0);
  }

  isComplete(): boolean {
    return this.phoneNumber.every((digit) => digit);
  }

  getFullNumber(): string {
    return this.phoneNumber.join('');
  }

  onSubmit(): void {
    if (this.phoneForm.valid) {
      this.dialogRef.close(this.getFullNumber());
    }
  }

  onCancel(): void {
    this.dialogRef.close('cancel');
  }
}
